// auth 2.0
import React from "react";
import env from "config/env";
import styles from "./VideoPlayer.module.scss";
import "./VideoPlayer.css";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "@slid/slid-ips";
import queryString from "query-string";
import { VideoSettingIcon } from "components/icons/VideoSettingIcon";
import { Typography17, Typography15 } from "@slid/slid-ips";
import { SkipForwardIcon } from "components/icons/SkipForwardIcon";
import { SkipBackwardIcon } from "components/icons/SkipBackwardIcon";
import { BeforeVideoIcon } from "components/icons/BeforeVideoIcon";
import { NextVideoIcon } from "components/icons/NextVideoIcon";
import { CloseIcon } from "components/icons/CloseIcon";
import { VideoDefaultScreenIcon } from "components/icons/VideoDefaultScreenIcon";
import { VideoFullScreenIcon } from "components/icons/VideoFullScreenIcon";
import { PlayIcon } from "components/icons/PlayIcon";
import { PauseIcon } from "components/icons/PauseIcon";
import ReactPlayer from "react-player";
import SweetAlert from "sweetalert2";
import ReactResizeDetector from "react-resize-detector";
import { setVideoInfoResponseListener } from "utils/extensionInterface/setListenerFromExtension";
import { sendVideoPlaceholderPositionToParentWindow, sendVideoControlRequestToParentWindow, sendVideoInfoRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { sendAmplitudeData } from "utils/amplitude";
import { connect } from "react-redux";
import {
  setVideoPlaceholderRect,
  setIsCaptureAreaActive,
  setVideoIndex,
  setIsVideoPlaying,
  setCurrentVideo,
  setVideoPlayerRef,
  setCaptureRect,
  setIsCaptureAreaSetForOneClickCapture,
} from "redux/actions/vdocsActions";
import { setStopSTT, setIsSTTToggledOn } from "redux/actions/sttActions";
// for router history
import { withRouter } from "react-router-dom";
import { isMacOs } from "react-device-detect";
import { Toast } from "react-bootstrap";
import { trackEvent } from "utils/eventTracking";
import { isIframe } from "utils/utils";
import { withHooks } from "withHooks";
import NoVideoThumbnail from "./NoVideoThumbnail";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import LoadingScreenContent from "components/editorLoadingStates/LoadingScreenContent";

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

class VideoPlayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isVideoControlable: false,
      playbackSpeed: 1,
      skipInterval: 5,
      isFullScreen: false,
      isPlaying: false,
      videoState: "loading", // loading, empty, available, unavailable
      showCurrentSpeed: false,
      isBackwardSkipBtnClicked: false,
      isForwardSkipBtnClicked: false,
      isPlayPauseBtnClicked: false,
      isDemoMode: false,
    };
    this.videoPlaceholderContainerRef = React.createRef();
    this.videoPlaceholderRef = React.createRef();
    this.videoUploadInputRef = React.createRef();
    this.videoPlayerRef = React.createRef();
    this.videoBackwardButtonRef = React.createRef();
    this.videoForwardButtonRef = React.createRef();
    this.showCurrentSpeedRef = React.createRef();
    this.videoPlayControllerContainer = React.createRef();
    this.setMessageListenerFromVideo = this.setMessageListenerFromVideo.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.currentDocument) {
      this.setState({
        videoState: "empty",
      });
    }

    if (document.location.href === `${SLID_WEB_APP_URL}/demo`) {
      this.setState({
        isDemoMode: true,
        videoState: "available",
      });
      if (this.props.applicationType) {
        this.setState({
          isVideoControlable: true,
        });
      }
    }
    // Move videoPlaceholder to the middle of the videoPlayer in iframe.
    if (isIframe()) {
      this.videoPlaceholderContainerRef.current.style.height = "100%";
    }
    document.addEventListener("keydown", this.setKeyDownListener.bind(this));
    window.addEventListener("resize", this.setResizeListener.bind(this));
    // Extension -- listen to the extension's message
    window.addEventListener("message", this.setMessageListenerFromVideo);

    if (this.props.applicationType === "extension") {
      this.setExtensionVideoInfoListener();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentDocument !== this.props.currentDocument) {
      switch (this.props.applicationType) {
        case "extension":
          break;
        case "web":
        case "mobile":
          // After clicking timestamp of image captured in web from the extension
          // It will open new tab with querystring v (videoKey) and start (timestamp)
          // So if there is `v` in querystring, switch to that video
          if (this.props.currentDocument) {
            const videoKeyFromQuery = queryString.parse(this.props.location.search).v;
            if (videoKeyFromQuery) {
              // If videoKey is defined in query string
              const newVideoIndex = this.props.currentDocument["mapped_videos"].findIndex((mappedVideo) => {
                return mappedVideo["video_key"] === videoKeyFromQuery;
              });
              this.props.setVideoIndex(newVideoIndex);
            }

            this.checkVideoState();
            // do we need to set video player controllers?
            this.checkVideoPlayerController();
          }
          break;
        default:
          return;
      }
    }
    if (prevProps.videoIndex !== this.props.videoIndex) {
      // do we need to set video player controllers?
      this.checkVideoPlayerController();
    }
    if (prevState.playbackSpeed !== this.state.playbackSpeed) {
      this.setShowCurrentSpeedToastPosition();
    }
    if (prevState.videoState !== this.state.videoState) {
      if (this.state.videoState === "unavailable") {
        trackEvent({
          eventType: `Video not available in video note`,
        });
        // If video is unavailable and it's shared note, redirect to plain note.
        if (this.props.location.pathname.includes("/share/vdocs/")) {
          this.props.history.push(`/share/docs/${this.props.currentDocument["document_key"]}`);
        }
      }
    }

    if (prevState.isPlaying !== this.state.isPlaying) {
      if (this.props.applicationType === "extension") {
        this.props.setIsVideoPlaying(this.state.isPlaying);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.setKeyDownListener.bind(this));
    window.removeEventListener("resize", this.setResizeListener.bind(this));
    window.removeEventListener("message", this.setMessageListenerFromVideo);
    this._isMounted = false;
  }

  setShowCurrentSpeedToastPosition() {
    this.setState({
      showCurrentSpeed: true,
    });
    if (this.videoPlayControllerContainer.current !== null) {
      const { height } = this.videoPlayControllerContainer.current.getBoundingClientRect();
      if (this.showCurrentSpeedRef.current !== null) {
        this.showCurrentSpeedRef.current.style.bottom = height + 15 + "px";
        this.showCurrentSpeedRef.current.style.right = 10 + "px";
      }
    }
  }

  setKeyDownListener(event) {
    if (!this._isMounted) return;
    if (event.ctrlKey || event.metaKey || event.altKey) {
      switch (event.code) {
        case "Space":
        case "KeyK":
          // short cut for play/pause
          event.preventDefault();
          this.toggleIsPlaying();
          sendAmplitudeData(`Use shortcut`, {
            type: `video play/pause`,
          });
          break;
        case "Comma":
        case "KeyJ":
          // short cut for backward
          event.preventDefault();
          if (this.videoBackwardButtonRef.current) {
            this.videoBackwardButtonRef.current.click();
          }
          sendAmplitudeData(`Use shortcut`, {
            type: `video backward`,
          });
          break;
        case "Period":
        case "KeyL":
          // short cut for forward
          event.preventDefault();
          if (this.videoForwardButtonRef.current) {
            this.videoForwardButtonRef.current.click();
          }
          sendAmplitudeData(`Use shortcut`, {
            type: `video forward`,
          });
          break;
        case "Semicolon": {
          // short cut for slower speed
          event.preventDefault();
          if (this.state.playbackSpeed <= 0.5) {
            this.setShowCurrentSpeedToastPosition();
            return;
          }
          const updatedPlaybackSpeed = this.state.playbackSpeed - 0.25;
          if (this.props.applicationType === "extension") {
            sendVideoControlRequestToParentWindow({
              type: "updatePlaybackRate",
              value: updatedPlaybackSpeed,
            });
          }
          this.setState({
            playbackSpeed: updatedPlaybackSpeed,
          });
          sendAmplitudeData(`Use shortcut`, {
            type: `video slower playback`,
          });
          break;
        }
        case "Quote": {
          // short cut for faster speed
          event.preventDefault();
          if (this.state.playbackSpeed >= 2.5) {
            this.setShowCurrentSpeedToastPosition();
            return;
          }
          const updatedPlaybackSpeed = this.state.playbackSpeed + 0.25;
          if (this.props.applicationType === "extension") {
            sendVideoControlRequestToParentWindow({
              type: "updatePlaybackRate",
              value: updatedPlaybackSpeed,
            });
          }
          this.setState({
            playbackSpeed: updatedPlaybackSpeed,
          });
          sendAmplitudeData(`Use shortcut`, {
            type: `video faster playback`,
          });
          break;
        }
        default:
          return;
      }
    }
  }

  /**
   * This method is for receiving keydown event from video which is in slid_extension.
   * @param {event} message
   */
  setMessageListenerFromVideo(message) {
    if (message.data.action === "CONTENT_TO_IFRAME_sendKeyboardShortcutEvent") {
      const shortCutEvent = new Event("keydown");
      const { code, altKey, ctrlKey, metaKey, shiftKey } = message.data.data.event;
      shortCutEvent.code = code;
      shortCutEvent.altKey = altKey;
      shortCutEvent.ctrlKey = ctrlKey;
      shortCutEvent.metaKey = metaKey;
      shortCutEvent.shiftKey = shiftKey;
      this.setKeyDownListener(shortCutEvent);
    }
  }

  setResizeListener(event) {
    if (!this._isMounted) return;
    /*
    on resize (including fullscreen toggle), send the new video placeholder rectangle
    to allow for resizing
    */
    if (this.props.applicationType === "extension" && this.videoPlaceholderRef.current) {
      const videoPlaceholderRect = this.videoPlaceholderRef.current.getBoundingClientRect();
      const rect = {
        top: videoPlaceholderRect.top,
        left: videoPlaceholderRect.left,
        width: videoPlaceholderRect.width,
        height: videoPlaceholderRect.height,
      };
      this.props.setVideoPlaceholderRect(rect);
      // reset capture area when video rect is updated
      this.props.setIsCaptureAreaActive(false);
      sendVideoPlaceholderPositionToParentWindow({
        videoPlaceholderPosition: videoPlaceholderRect,
      });
    } else if (this.props.applicationType === "web") {
      const videoPlaceholderRect = this.videoPlaceholderRef.current.getBoundingClientRect();
      const rect = {
        top: 2.5,
        left: 2.5,
        width: videoPlaceholderRect.width - 5,
        height: videoPlaceholderRect.height - 5,
      };
      this.props.setCaptureRect(rect);
      this.props.setIsCaptureAreaSetForOneClickCapture(false); // turn canvas for one click capture off
    }
  }

  setVideoPlayerControllers(videoType = null) {
    switch (videoType) {
      case "iframe":
      case "div":
        this.setState({
          isVideoControlable: false,
        });
        break;
      default:
        this.setState({
          isVideoControlable: true,
        });
        break;
    }
  }

  toggleFullScreen() {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        this.setState({ isFullScreen: false });
        return;
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
        this.setState({ isFullScreen: false });
        return;
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        this.setState({ isFullScreen: false });
        return;
      }
    } else {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
        this.setState({ isFullScreen: true });
        return;
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
        this.setState({ isFullScreen: true });
        return;
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
        this.setState({ isFullScreen: true });
        return;
      }
    }
    SweetAlert.fire({
      title: this.props.lang === "ko" ? "전체화면을 이용할 수 없습니다." : "Not supported.",
      html: this.props.lang === "ko" ? "해당 브라우저에서는 전체화면을 지원하고 있지 않습니다." : "Full Screen mode is not supported in this browser.",
      icon: "error",
      confirmButtonText: this.props.lang === "ko" ? "닫기" : "Close",
    });
  }

  renderVideoPlaceholder(width) {
    switch (this.state.videoState) {
      case "loading":
        return (
          <LoadingWrapper>
            <LoadingScreenContent loadingText={this.props.lang === "ko" && "동영상을 불러오는 중이에요!"} textColor="--gray1" />
          </LoadingWrapper>
        );

      case "empty":
        return <NoVideoThumbnail thumbnailTitle={this.props.t("NoVideoThumbnailTitle", { ns: "VideoNote" })} thumbnailText={this.props.t("NoVideoThumbnailText", { ns: "VideoNote" })} />;
      case "available":
        return this.getVideoPlayer(width);
      case "error":
      case "unavailable":
        return (
          <NoVideoThumbnail
            thumbnailTitle={this.props.t("UnavailableVideoThumbnailTitle", { ns: "VideoNote" })}
            thumbnailText={this.props.t("UnavailableVideoThumbnailText", { ns: "VideoNote" })}
            videoLink={
              this.props.currentDocument &&
              this.props.currentDocument["mapped_videos"] &&
              this.props.currentDocument["mapped_videos"][this.props.videoIndex] &&
              this.props.currentDocument["mapped_videos"][this.props.videoIndex]["website_origin_url"]
            }
          />
        );
      default:
        return <NoVideoThumbnail thumbnailTitle={this.props.t("NoVideoThumbnailTitle", { ns: "VideoNote" })} thumbnailText={this.props.t("NoVideoThumbnailText", { ns: "VideoNote" })} />;
    }
  }

  setCurrentVideoOnWeb() {
    if (!this.props.currentDocument || !this.props.currentDocument["mapped_videos"]) return;
    const currentVideo = this.props.currentDocument["mapped_videos"][this.props.videoIndex];
    if (!currentVideo) return;
    this.props.setCurrentVideo({
      originUrl: currentVideo.website_origin_url,
      videoUrl: currentVideo.video_origin_url,
      videoType: currentVideo.video_type,
      videoUniqueKey: currentVideo.video_unique,
      videoKey: currentVideo.video_key,
    });
  }

  getVideoPlayer(width) {
    if (this.state.isDemoMode) {
      return (
        <>
          <ReactPlayer
            className={`${styles[`video-player`]} slid-video-player`}
            url={this.props.lang === "ko" ? "src/video/Slid_demo_video_ko.webm" : "src/video/Slid_demo_video_en.webm"}
            playing={this.state.isPlaying}
            ref={this.videoPlayerRef}
            onReady={() => {
              const videoPlayerElement = this.videoPlayerRef.current.getInternalPlayer().g;
              if (videoPlayerElement) {
                videoPlayerElement.setAttribute("data-hj-allow-iframe", "");
              }
              this.videoPlayerRef.current.forcePlay = () => {
                this.setState({
                  isPlaying: true,
                });
              };
              this.props.setVideoPlayerRef(this.videoPlayerRef.current);
              this.setVideoPlayerControllers();
            }}
            onPlay={() => {
              this.setState({
                isPlaying: true,
              });
            }}
            onPause={() => {
              this.setState({
                isPlaying: false,
              });
            }}
            onError={(e) => {
              console.error(e);
              this.setState({
                videoState: "unavailable",
              });
            }}
            controls={true}
            playbackRate={this.state.playbackSpeed}
            config={{
              file: {
                attributes: {
                  onContextMenu: (e) => e.preventDefault(),
                  controlsList: "nodownload",
                },
              },
            }}
          />
          {this.props.isCaptureAreaSetForSnipCapture && (
            <div id="canvas-wrapper">
              <canvas id="slid-video-snip-capture-area"></canvas>
            </div>
          )}
          {this.props.isCaptureAreaSetForOneClickCapture && (
            <div id="canvas-wrapper">
              <canvas id="slid-video-one-click-capture-area"></canvas>
            </div>
          )}
        </>
      );
    }

    if (!this.props.currentDocument || !this.props.currentDocument["mapped_videos"] || !this.props.currentDocument["mapped_videos"][this.props.videoIndex]) {
      this.setState({
        videoState: "loading",
      });
      return;
    }
    switch (this.props.currentDocument["mapped_videos"][this.props.videoIndex]["video_type"]) {
      case "youtube":
      case "youtube_nocookie":
      case "video":
      case "vimeo":
        return (
          <>
            <ReactPlayer
              // NOTE: width is needed to prevent issue when scrolling inside the add menu of lexical editor, the embedded video (iframe) flickers or shifts unexpectedly, even though its size remains unchanged
              width={width}
              className={`${styles[`video-player`]} slid-video-player`}
              url={this.props.currentDocument["mapped_videos"][this.props.videoIndex]["video_origin_url"]}
              playing={this.state.isPlaying}
              ref={this.videoPlayerRef}
              onReady={() => {
                const videoPlayerElement = this.videoPlayerRef.current.getInternalPlayer().g;
                if (videoPlayerElement) {
                  videoPlayerElement.setAttribute("data-hj-allow-iframe", "");
                }
                this.videoPlayerRef.current.forcePlay = () => {
                  this.setState({
                    isPlaying: true,
                  });
                };
                this.props.setVideoPlayerRef(this.videoPlayerRef.current);
                if ((this.props.applicationType === "web" || this.props.applicationType === "mobile") && !this.state.isDemoMode) {
                  this.setCurrentVideoOnWeb();
                }

                const queryParams = queryString.parse(this.props.location.search);
                if (queryParams.start) {
                  this.videoPlayerRef.current.forcePlay();
                  const timestamp = Math.floor(queryParams.start);
                  this.videoPlayerRef.current.seekTo(timestamp);

                  //we remove only the start query param from the url after we have used it
                  delete queryParams.start;
                  const newQueryString = queryString.stringify(queryParams, { encode: false });
                  const newURL = this.props.location.pathname + (newQueryString ? `?${newQueryString}` : "");

                  // Replace the current URL with the new URL without the start query param
                  this.props.history.replace(newURL);
                }
              }}
              onPlay={() => {
                this.setState({
                  isPlaying: true,
                });
              }}
              onPause={() => {
                this.setState({
                  isPlaying: false,
                });
              }}
              onError={(e) => {
                console.error(e);
                this.setState({
                  videoState: "unavailable",
                });
              }}
              controls={true}
              playbackRate={this.state.playbackSpeed}
              config={{
                file: {
                  attributes: {
                    onContextMenu: (e) => e.preventDefault(),
                    controlsList: "nodownload",
                  },
                },
              }}
            />
            {this.props.isCaptureAreaSetForSnipCapture && (
              <div id="canvas-wrapper">
                <canvas id="slid-video-snip-capture-area"></canvas>
              </div>
            )}
            {this.props.isCaptureAreaSetForOneClickCapture && (
              <div id="canvas-wrapper">
                <canvas id="slid-video-one-click-capture-area"></canvas>
              </div>
            )}
          </>
        );
      case "iframe":
        return (
          <>
            <iframe
              className={`${styles[`video-player`]} slid-video-player-iframe`}
              src={this.props.currentDocument["mapped_videos"][this.props.videoIndex]["video_origin_url"]}
              sandbox={`allow-scripts allow-forms allow-same-origin allow-top-navigation-by-user-activation allow-popups-to-escape-sandbox allow-modals`}
              title="video-iframe"
              onLoad={() => {
                if (this.props.applicationType === "web" || this.props.applicationType === "mobile") {
                  this.setCurrentVideoOnWeb();
                }
              }}
            ></iframe>
            {this.props.isCaptureAreaSetForSnipCapture && (
              <div id="canvas-wrapper">
                <canvas id="slid-video-snip-capture-area"></canvas>
              </div>
            )}
            {this.props.isCaptureAreaSetForOneClickCapture && (
              <div id="canvas-wrapper">
                <canvas id="slid-video-one-click-capture-area"></canvas>
              </div>
            )}
          </>
        );
      case "google_drive":
        return this.setState({
          videoState: "unavailable",
        });
      default:
        return;
    }
  }

  setExtensionVideoInfoListener() {
    this.setVideoInfoResponseListenerFromExtension();
    sendVideoInfoRequestToParentWindow();
    // set listener for youtube / vimeo / pure-video player play/pause/end
    window.addEventListener("message", (message) => {
      const { action, data } = message.data;
      switch (action) {
        case "CONTENT_TO_IFRAME_pausedVideo":
          trackEvent({
            eventType: `Pause VIDEO in video note page`,
          });
          this.setState({
            isPlaying: false,
          });
          break;
        case "CONTENT_TO_IFRAME_playedVideo":
          trackEvent({
            eventType: `Play VIDEO in video note page`,
          });
          this.setState({
            isPlaying: true,
          });
          break;
        case "CONTENT_TO_IFRAME_endedVideo":
          trackEvent({
            eventType: `End VIDEO in video note page`,
          });
          this.setState({
            isPlaying: false,
          });
          if (this.props.isSTTToggledOn) this.props.setIsSTTToggledOn(false);
          if (this.props.isSTTActive) this.props.setStopSTT();
          if (this.props.isWhisperSLTToggledOn) this.props.onToggleOffSLTButton();
          break;
        case "CONTENT_TO_IFRAME_changePlaybackRate":
          this.setState({
            playbackSpeed: data.value,
          });
          break;
        default:
          return;
      }
    });
  }

  checkVideoState() {
    if ((!this.props.currentDocument || !this.props.currentDocument["mapped_videos"] || this.props.currentDocument["mapped_videos"]?.length === 0) && !this.state.isDemoMode) {
      this.setState({
        videoState: "empty",
      });
    } else {
      this.setState({
        videoState: "available",
      });
    }
  }

  checkVideoPlayerController() {
    if (this.props.currentDocument && this.props.currentDocument["mapped_videos"] && this.props.currentDocument["mapped_videos"][this.props.videoIndex]) {
      this.setVideoPlayerControllers(this.props.currentDocument["mapped_videos"][this.props.videoIndex]["video_type"]);
    } else {
      this.setState({
        isVideoControlable: false,
      });
    }
  }

  toggleIsPlaying() {
    if (this.props.applicationType === "extension") {
      sendVideoControlRequestToParentWindow({
        type: "updatePlay",
        value: !this.state.isPlaying,
      });
    }
    this.setState({
      isPlaying: !this.state.isPlaying,
    });
    this.setState({
      isPlayPauseBtnClicked: true,
    });
    setTimeout(() => {
      this.setState({
        isPlayPauseBtnClicked: false,
      });
    }, 100);
  }

  updateVideoTime({ direction }) {
    if (direction === "backward") {
      if (this.props.applicationType === "extension") {
        sendVideoControlRequestToParentWindow({
          type: "updateVideoTime",
          value: -this.state.skipInterval,
        });
      } else {
        const timestamp = this.props.videoPlayerRef.getCurrentTime() - this.state.skipInterval;
        this.props.videoPlayerRef.seekTo(timestamp);
      }
    } else {
      if (this.props.applicationType === "extension") {
        sendVideoControlRequestToParentWindow({
          type: "updateVideoTime",
          value: this.state.skipInterval,
        });
      } else {
        const timestamp = this.props.videoPlayerRef.getCurrentTime() + this.state.skipInterval;
        this.props.videoPlayerRef.seekTo(timestamp);
      }
    }
  }

  setVideoInfoResponseListenerFromExtension() {
    setVideoInfoResponseListener({
      responseHandler: (receivedData) => {
        this.setVideoPlayerControllers(receivedData["videoType"]);
        const videoInfo = receivedData;
        this.props.setCurrentVideo(videoInfo);
        trackEvent({
          eventType: `Set video in video note`,
          eventProperties: { ...videoInfo },
        });
      },
    });
  }

  render() {
    return (
      <div className={`${styles[`video-container`]} ${this.props.screenOrientation === "vertical" ? styles[`mobile`] : ""}`}>
        {this.props.applicationType === "extension" || this.props.screenOrientation === "vertical" ? null : (
          <div className={styles[`video-view-controller-container`]}>
            {this.props.applicationType !== "web" && (
              <div
                className={styles[`video-view-controller-button`]}
                onClick={() => {
                  const documentKey = this.props.currentDocument ? this.props.currentDocument["document_key"] : "new";
                  this.props.history.push(`/docs/${documentKey}`);
                }}
                draggable={false}
              >
                <CloseIcon width={16} height={16} />
              </div>
            )}
            <div
              className={styles[`video-view-controller-button`]}
              onClick={() => {
                this.toggleFullScreen();
              }}
              draggable={false}
            >
              {this.state.isFullScreen ? <VideoDefaultScreenIcon width={20} height={20} /> : <VideoFullScreenIcon width={19} height={16} />}
            </div>
          </div>
        )}
        <div className={styles[`video-placeholder-container`]} ref={this.videoPlaceholderContainerRef}>
          {!this.props.isAuthRequired ? (
            <ReactResizeDetector handleWidth targetRef={this.videoPlaceholderRef}>
              {({ width }) => {
                if (this.props.applicationType === "extension" && this.videoPlaceholderRef.current) {
                  const videoPlaceholderRect = this.videoPlaceholderRef.current.getBoundingClientRect();
                  if (this.props.videoPlaceholderRect.width !== videoPlaceholderRect.width) {
                    const rect = {
                      top: videoPlaceholderRect.top,
                      left: videoPlaceholderRect.left,
                      width: videoPlaceholderRect.width,
                      height: videoPlaceholderRect.height,
                    };
                    this.props.setVideoPlaceholderRect(rect);
                    // reset capture area when video rect is updated
                    this.props.setIsCaptureAreaActive(false);
                    sendVideoPlaceholderPositionToParentWindow({
                      videoPlaceholderPosition: videoPlaceholderRect,
                    });
                  }
                }
                return (
                  <div id={`video-placeholder`} className={`${styles[`video-placeholder`]} ${styles[this.props.screenOrientation]}`} ref={this.videoPlaceholderRef}>
                    <img
                      draggable={false}
                      src={`/src/design/assets/slid_recording_video_red_icon.png`}
                      alt=""
                      className={this.props.isRecordActive ? styles[`recoding-red-icon`] : styles[`recoding-red-icon-hide`]}
                      id="recoding-red-icon"
                    />
                    {this.props.applicationType === "extension" ? null : this.renderVideoPlaceholder(width)}
                  </div>
                );
              }}
            </ReactResizeDetector>
          ) : (
            <></>
          )}
        </div>
        {this.props.screenOrientation === "vertical" ? null : (
          <div className={styles[`video-play-controller-container`]} ref={this.videoPlayControllerContainer}>
            <div className={styles[`setting-container`]}>
              {this.state.isVideoControlable ? (
                <Dropdown drop={this.props.screenOrientation === "vertical" ? `down` : `right`}>
                  <Dropdown.Toggle variant={`white`} className={styles[`setting-toggle`]}>
                    <div className={styles[`setting-icon`]}>
                      <VideoSettingIcon width={28} height={28} />
                    </div>
                    {this.props.screenOrientation === "vertical" || this.props.showSmartLiveTextView || this.props.isSmallVideoPlayer ? null : (
                      <Typography15 color={`--gray2`} text={this.props.lang === "ko" ? "동영상 설정" : "Video Setting"} />
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={`${styles[`setting-popup`]} ${styles[this.props.screenOrientation !== "vertical" && "vnote-video-setting"]}`}>
                    <div>
                      <div className={`${styles[`setting-option`]}`}>
                        <span>{this.props.lang === "ko" ? "구간 이동 간격" : "Skip interval"}</span>
                        <select
                          className={`${styles[`select`]} custom-select custom-select-sm`}
                          value={this.state.skipInterval}
                          onChange={(event) => {
                            this.setState({
                              skipInterval: parseInt(event.target.value),
                            });
                          }}
                        >
                          <option value={5}>5s</option>
                          <option value={10}>10s</option>
                          <option value={30}>30s</option>
                          <option value={60}>60s</option>
                        </select>
                      </div>
                      <div className={`${styles[`setting-option`]}`}>
                        <span>{this.props.lang === "ko" ? "재생 속도" : "Playback Speed"}</span>
                        <select
                          className={`${styles[`select`]} custom-select custom-select-sm`}
                          value={this.state.playbackSpeed}
                          onChange={(event) => {
                            if (this.props.applicationType === "extension") {
                              sendVideoControlRequestToParentWindow({
                                type: "updatePlaybackRate",
                                value: parseFloat(event.target.value),
                              });
                            }
                            this.setState({
                              playbackSpeed: parseFloat(event.target.value),
                            });
                          }}
                        >
                          <option value={0.5}>0.5x</option>
                          <option value={0.75}>0.75x</option>
                          <option value={1}>1x</option>
                          <option value={1.25}>1.25x</option>
                          <option value={1.5}>1.5x</option>
                          <option value={1.75}>1.75x</option>
                          <option value={2}>2x</option>
                          <option value={2.25}>2.25x</option>
                          <option value={2.5}>2.5x</option>
                        </select>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
            </div>
            {this.state.isVideoControlable ? (
              <div className={styles[`control-container`]}>
                <Tooltip placement={`top`} title={this.props.lang === "ko" ? `${this.state.skipInterval}초 뒤로` : `Rewind`} description={isMacOs ? "(Cmd + J)" : "(Alt + J)"}>
                  <button
                    ref={this.videoBackwardButtonRef}
                    className={`${this.state.isBackwardSkipBtnClicked ? styles["skip-btn-clicked"] : styles[`skip-btn`]}`}
                    onClick={() => {
                      this.updateVideoTime({
                        direction: "backward",
                      });
                      this.setState({
                        isBackwardSkipBtnClicked: true,
                      });
                      setTimeout(() => {
                        this.setState({
                          isBackwardSkipBtnClicked: false,
                        });
                      }, 100);
                    }}
                  >
                    <Typography17 text={`-${this.state.skipInterval}`} color={`--gray2`} weight={700} />
                    <div className={styles[`skip-backward-icon`]}>
                      <SkipBackwardIcon width={28} height={28} />
                    </div>
                  </button>
                </Tooltip>
                <Tooltip
                  placement={`top`}
                  title={this.state.isPlaying ? (this.props.lang === "ko" ? `일시정지` : `Pause`) : this.props.lang === "ko" ? `재생` : `Play`}
                  description={isMacOs ? "(Cmd + K)" : "(Alt + K)"}
                >
                  <button
                    className={`${this.state.isPlayPauseBtnClicked ? styles[`play-btn-clicked`] : styles[`play-btn`]}`}
                    onClick={() => {
                      this.toggleIsPlaying();
                    }}
                  >
                    {this.state.isPlaying ? <PauseIcon width={40} height={40} /> : <PlayIcon width={36} height={36} />}
                  </button>
                </Tooltip>
                <Tooltip placement={`top`} title={this.props.lang === "ko" ? `${this.state.skipInterval}초 앞으로` : `Fast-forward`} description={isMacOs ? "(Cmd + L)" : "(Alt + L)"}>
                  <button
                    ref={this.videoForwardButtonRef}
                    className={`${this.state.isForwardSkipBtnClicked ? styles["skip-btn-clicked"] : styles[`skip-btn`]}`}
                    onClick={() => {
                      this.updateVideoTime({
                        direction: "forward",
                      });
                      this.setState({
                        isForwardSkipBtnClicked: true,
                      });
                      setTimeout(() => {
                        this.setState({
                          isForwardSkipBtnClicked: false,
                        });
                      }, 100);
                    }}
                  >
                    <div className={styles[`skip-forward-icon`]}>
                      <SkipForwardIcon width={28} height={28} />
                    </div>
                    <Typography17 text={`+${this.state.skipInterval}`} color={`--gray2`} weight={700} />
                  </button>
                </Tooltip>
              </div>
            ) : null}
            {this.props.applicationType === "extension" ||
            this.props.screenOrientation === "vertical" ||
            this.state.isDemoMode ||
            !this.props.currentDocument ||
            this.props.currentDocument["mapped_videos"]?.length <= 1 ? null : (
              <div className={styles[`select-controller-container`]}>
                <Tooltip placement={`top`} title={this.props.lang === "ko" ? "이전 영상" : "Previous video"}>
                  <button
                    className={`${styles[`skip-btn`]}`}
                    disabled={this.props.videoIndex === 0}
                    onClick={() => {
                      if (this.props.videoIndex - 1 < 0) return;
                      const videoIndex = this.props.videoIndex - 1;
                      this.props.setVideoIndex(videoIndex);
                    }}
                  >
                    <BeforeVideoIcon width={11} height={12} color={this.props.videoIndex === 0 && "var(--gray9)"} />
                  </button>
                </Tooltip>
                <Tooltip placement={`top`} title={this.props.lang === "ko" ? "다음 영상" : "Next video"}>
                  <button
                    className={`${styles[`skip-btn`]}`}
                    disabled={
                      // Or if video index is last of the mapped videos
                      this.props.currentDocument["mapped_videos"]?.length - 1 < this.props.videoIndex + 1
                    }
                    onClick={() => {
                      const videoIndex = this.props.videoIndex + 1;
                      this.props.setVideoIndex(videoIndex);
                    }}
                  >
                    <NextVideoIcon width={11} height={12} color={this.props.currentDocument["mapped_videos"]?.length - 1 < this.props.videoIndex + 1 && "var(--gray9)"} />
                  </button>
                </Tooltip>
              </div>
            )}
            <div ref={this.showCurrentSpeedRef} id={`${styles[`showCurrentSpeedToastDiv`]}`}>
              <Toast
                autohide
                onClose={() => {
                  this.setState({
                    showCurrentSpeed: false,
                  });
                }}
                delay={1000}
                show={this.state.showCurrentSpeed}
              >
                <Toast.Body>{this.props.lang === "ko" ? `현재 재생 속도 : ${this.state.playbackSpeed}x` : `Playback Speed : ${this.state.playbackSpeed}x`}</Toast.Body>
              </Toast>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const actions = {
  setVideoPlaceholderRect,
  setIsCaptureAreaActive,
  setVideoIndex,
  setIsVideoPlaying,
  setCurrentVideo,
  setVideoPlayerRef,
  setCaptureRect,
  setIsCaptureAreaSetForOneClickCapture,
  setStopSTT,
  setIsSTTToggledOn,
};
const mapStateToProps = (state) => ({
  screenOrientation: state.slidGlobal.screenOrientation,
  lang: state.slidGlobal.lang,
  applicationType: state.slidGlobal.applicationType,
  currentDocument: state.vdocs.currentDocument,
  videoIndex: state.vdocs.videoIndex,
  videoPlayerRef: state.vdocs.videoPlayerRef,
  videoPlaceholderRect: state.vdocs.videoPlaceholderRect,
  isRecordActive: state.vdocs.isRecordActive,
  isCaptureAreaSetForSnipCapture: state.vdocs.isCaptureAreaSetForSnipCapture,
  isCaptureAreaSetForOneClickCapture: state.vdocs.isCaptureAreaSetForOneClickCapture,
  currentVideo: state.vdocs.currentVideo,
  showSmartLiveTextView: state.sttReducer.showSmartLiveTextView,
  isSTTActive: state.sttReducer.isSTTActive,
  isSTTToggledOn: state.sttReducer.isSTTToggledOn,
});

export default withTranslation()(connect(mapStateToProps, actions)(withRouter(withHooks(VideoPlayer))));

const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--gray17);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
